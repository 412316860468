import {h} from 'preact'

export default () => {
    return (
        <div className="result-list skeleton-holder">
            <div className="card">
                <a href="stylist-profile.html" className="stylist-card">
                <span className="images-row">
                  <span className="image-item">
                    <span className="image">
                      <span className="skeleton-square"></span>
                    </span>
                  </span>
                  <span className="image-item">
                    <span className="image">
                      <span className="skeleton-square"></span>
                    </span>
                  </span>
                  <span className="image-item">
                    <span className="image">
                      <span className="skeleton-square"></span>
                    </span>
                  </span>
                </span>
                    <strong className="title-row">
                        <span className="skeleton-row"></span>
                    </strong>
                    <span className="info-row">
                  <span className="skeleton-row"></span>
                </span>
                    <span className="skeleton-btn-holder">
                  <span className="skeleton-row"></span>
                </span>
                </a>
            </div>
            <div className="card">
                <a href="stylist-profile.html" className="stylist-card">
                <span className="images-row">
                  <span className="image-item">
                    <span className="image">
                      <span className="skeleton-square"></span>
                    </span>
                  </span>
                  <span className="image-item">
                    <span className="image">
                      <span className="skeleton-square"></span>
                    </span>
                  </span>
                  <span className="image-item">
                    <span className="image">
                      <span className="skeleton-square"></span>
                    </span>
                  </span>
                </span>
                    <strong className="title-row">
                        <span className="skeleton-row"></span>
                    </strong>
                    <span className="info-row">
                  <span className="skeleton-row"></span>
                </span>
                    <span className="skeleton-btn-holder">
                  <span className="skeleton-row"></span>
                </span>
                </a>
            </div>
            <div className="card">
                <a href="stylist-profile.html" className="stylist-card">
                <span className="images-row">
                  <span className="image-item">
                    <span className="image">
                      <span className="skeleton-square"></span>
                    </span>
                  </span>
                  <span className="image-item">
                    <span className="image">
                      <span className="skeleton-square"></span>
                    </span>
                  </span>
                  <span className="image-item">
                    <span className="image">
                      <span className="skeleton-square"></span>
                    </span>
                  </span>
                </span>
                    <strong className="title-row">
                        <span className="skeleton-row"></span>
                    </strong>
                    <span className="info-row">
                  <span className="skeleton-row"></span>
                </span>
                    <span className="skeleton-btn-holder">
                  <span className="skeleton-row"></span>
                </span>
                </a>
            </div>
            <div className="card">
                <a href="stylist-profile.html" className="stylist-card">
                <span className="images-row">
                  <span className="image-item">
                    <span className="image">
                      <span className="skeleton-square"></span>
                    </span>
                  </span>
                  <span className="image-item">
                    <span className="image">
                      <span className="skeleton-square"></span>
                    </span>
                  </span>
                  <span className="image-item">
                    <span className="image">
                      <span className="skeleton-square"></span>
                    </span>
                  </span>
                </span>
                    <strong className="title-row">
                        <span className="skeleton-row"></span>
                    </strong>
                    <span className="info-row">
                  <span className="skeleton-row"></span>
                </span>
                    <span className="skeleton-btn-holder">
                  <span className="skeleton-row"></span>
                </span>
                </a>
            </div>
        </div>
    )
}
import {h} from 'preact'
import iconClose from '../../assets/images/icon-close.svg'
import {useState} from "preact/hooks";
import {capitalizeFirstLetter} from "../../utils/functions";
export default ({onBack, items, onClose, onSelect, name='', step = 1}) => {
    const [search, setSearch] = useState('')
    return (
        <>
            <div className="menu-header">
                <div className="title">
                    <button className="btn-back" onClick={onBack}><img src={'/assets/images/icon-back.svg'} alt="Back to previous step" />
                    </button>
                    <h3>Choose {capitalizeFirstLetter(name)}</h3>
                </div>
                <button className="btn-close" onClick={onClose}><img src={iconClose} alt="Close menu" /></button>
            </div>
            <p className="step">Step {step}/3</p>
            <div className="input-holder">
                <input value={search} onInput={(e) => setSearch(e.target.value)} type="text" className="input-search" placeholder={`Start entering your ${name}`}/>
            </div>
            <div className="scrollable">
                <ul className="radio-list">
                    {items.filter(el => {
                        return search === '' || el.name.toLowerCase().includes(search.toLowerCase())
                    }).map(item => {
                        return (
                            <li key={ name+'-select-list-' + item.name} onClick={() => {
                                onSelect(item)
                                setSearch('')
                            }}>
                                <label className="radio-button">
                                    <input type="radio" name={name}/>
                                    <span>
                                        {item.flag && <img style={{width: '33px'}} src={item.flag} alt={item.name} />}
                                        {item.name}
                                    </span>
                                </label>
                            </li>
                        )
                    })}

                </ul>
            </div>
        </>
    )
}
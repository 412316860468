import {createContext, h} from 'preact'
import {useEffect, useState} from "preact/hooks";
import App from "../components/app";
import {
    getInitInfo,
    getLocalizationKeys,
    getLocations,
    getPopularStylists,
    getSalons
} from "../data-providers/common-data-provicer";
import {route} from "preact-router";
import {PER_PAGE_OPTION} from "../constants/settings";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {capitalizeFirstLetter, scrollToTop} from "../utils/functions";

export const HomeContext = createContext({open: false, setOpen: null});

const defaultContex = {
    openedPopup: false,
    locations: [],
    availableCountries: [],
    mainCities: [],
    popupName: '',
    service: null,
    country: null,
    city: null,
    area: null,
    searchText: '',
    salons: [],
    page: 1,
    maxPage: 1,
    searchResultsLoaded: false,
    isNigeria: true,
    isGhana: false,
    isKenya: false
}

export default () => {

    const [context, setContext] = useState(defaultContex)
    const [popularStylists, setPopularStylists] = useState([])
    const [localization, setLocalization] = useState({})
    const [settings, setSettings] = useState({})

    const {executeRecaptcha} = useGoogleReCaptcha();
    const handleReCaptchaVerify = async () => {
        return  await executeRecaptcha('searchSubmit');
    };


    const setSearch = (search) => {
        if (!search) {
            setContext({...context, searchText: ''});
            return;
        }
        const updatedContext = {
            ...context,
            searchText: search,
            openedPopup: false,
            popupName: '',
            page: 1,
            salons: [],
        }

        handleReCaptchaVerify().then(token => {
            getSalonsList(updatedContext, token)
        })

    }

    const getSalonsList = async (params, token = '', cityId = null) => {
        if (params.page === 1) scrollToTop();
        setContext({...params, searchResultsLoaded: false, popupName: '', openedPopup: false})
        route('/stylists')
        const res = await getSalons(params.country?.id, cityId || params.city?.id, params.area?.id, params.service?.categorySlug, params.searchText, params.page, PER_PAGE_OPTION, token);
        setContext({...params,
            salons: [...new Map(params.salons.concat(res.data).map(item =>
                [item['stylist_id'], item])).values()],
            maxPage: res.max_page || res.meta.last_page,
            searchResultsLoaded: true
        });

    }

    const setCountry = (co) => {
        setContext({
            ...context,
            page: 1,
            salons: [],
            country: co,
            isNigeria: co.name !== 'Ghana' && co.name !== 'Kenya',
            isGhana: co.name === 'Ghana',
            isKenya: co.name === 'Kenya',
            popupName: 'cities',
            city: null,
            area: null,
            openedPopup: true,
        })
    }
    const setCity = (c) => {
        const updatedContext = {
            ...context,
            page: 1,
            searchText: '',
            salons: [],
            city: c,
            area: null,
        }
        if (c.areas.length) {
            updatedContext.popupName = 'areas'
            updatedContext.openedPopup = true
            setContext(updatedContext)
        } else {
            updatedContext.popupName = '';
            updatedContext.openedPopup = false;
            setContext(updatedContext)
            handleReCaptchaVerify().then(token => {
                getSalonsList(updatedContext, token)
            })
        }

    }
    const setArea = (a) => {
        const updatedContext = {
            ...context,
            page: 1,
            salons: [],
            popupName: '',
            openedPopup: false,
            area: a
        }
        setContext(updatedContext)
        handleReCaptchaVerify().then(token => {
            getSalonsList(updatedContext, token)
        })
    }

    const setService = (service) => {
        const updatedContext = {
            ...context,
            searchText: '',
            page: 1,
            salons: [],
            service
        }

        if (context.locations.length > 1 && !context.country) {
            updatedContext.popupName = 'countries';
        } else if (!context.country) {
            updatedContext.country = context.locations[0];
            updatedContext.popupName = 'cities'

        } else {
            updatedContext.popupName = '';
            updatedContext.openedPopup = false
            handleReCaptchaVerify().then(token => {
                getSalonsList(updatedContext, token)
            })
        }
        setContext(updatedContext)
    }

    const findInCapital = (capital) => {
        let  city;
        if (capital === 'Lagos') {
            city = context.mainCities.filter((el) => el.name === 'Lagos')[0]
        } else if (capital === 'Accra'){
            city = context.mainCities.filter((el) => el.name === 'Accra')[0]
        } else {
            city = context.mainCities.filter((el) => el.name === 'Nairobi')[0]
        }
        const updatedContext = {
            ...context,
            city,
            page: 1,
            salons: [],
            openedPopup: false,
            popupName: '',
            service: null,
            area: null
        }
        handleReCaptchaVerify().then(token => {
            getSalonsList({...updatedContext, country: null}, token)
        })

    }

    useEffect(() => {
        let isNigeria = true;
        let isGhana = false;
        let isKenya = false;
        let countryName = 'Nigeria'
        getLocations().then((loc) => {
            
            if (context.country?.name === 'Ghana' || (loc.locations.length === 1 && loc.locations[0].name === 'Ghana')) {
                isNigeria = false
                isGhana = true
                isKenya = false
                countryName = 'Ghana'
            }
            if (context.country?.name === 'Kenya' || (loc.locations.length === 1 && loc.locations[0].name === 'Kenya')) {
                isNigeria = false
                isGhana = false
                isKenya = true
                countryName = 'Kenya'
            }
            setContext({...context, locations: loc.locations, isNigeria, isKenya, isGhana, availableCountries: loc.availableCountries, mainCities: loc.mainCities})
        }).then(() => {
            getPopularStylists(6, countryName).then((res) => setPopularStylists(res.data))
        });
        
        
    }, []);
    useEffect(() => {
        getLocalizationKeys().then((res) => {
            let keys = {};
            res.map(el => {
                keys[el.key] = el.value || el.default_value
            })
            setLocalization(keys)
        })
        getInitInfo().then((res) => {
           
            setSettings(res)
        })
        
        
    }, []);
    
    useEffect(() => {
        let countryName = 'Nigeria';
        if (context.isGhana) {
            countryName = 'Ghana'
        }
        if (context.isKenya) {
            countryName = 'Kenya'
        }
        getPopularStylists(6, countryName).then((res) => setPopularStylists(res.data))
    }, [context.isNigeria, context.isKenya, context.isGhana])

    const openPopup = (popupTitle, resetServices = false) => {
        const updatedContext = {
            ...context,
            openedPopup: true,
            popupName: popupTitle
        }
        if (resetServices) {
            updatedContext.service = null;
        }
        setContext(updatedContext)
    }

    const closePopup = () => {
        setContext({
            ...context,
            openedPopup: false,
            popupName: ''
        })
    }

    const increasePage = () => {
        if (context.page >= context.maxPage) {
            return ;
        }
        const updatedContext = {
            ...context,
            page: context.page + 1
        }
        setContext(updatedContext)
        handleReCaptchaVerify().then(token => {
            getSalonsList(updatedContext, token)
        })
    }
    
    const getTranslation = (key, defaultValue = '', firstToUpper = false) => {
        let val = localization[key] || defaultValue
        if (firstToUpper) {
            return capitalizeFirstLetter(val);
        }
        return val
    }

    return (
        <HomeContext.Provider value={{
            getTranslation,
            increasePage,
            setService,
            setCountry,
            setCity,
            setArea,
            openPopup,
            closePopup,
            setSearch,
            findInCapital,
            popularStylists,
            settings,
            ...context,
            setContext
        }}>
            <App />
        </HomeContext.Provider>
    )
}
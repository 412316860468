import {h} from 'preact'
import {useContext, useEffect, useState} from "preact/hooks";

import SalonCard from "../blocks/SalonCard";
import {route} from "preact-router";

import {getPopularStylists} from "../../data-providers/common-data-provicer";
import {HomeContext} from "../../context/HomeContext";
import SearchResultsSkeleton from "../skeletons/SearchResultsSkeleton";

export default () => {
    const [recommended, setRecommended] = useState([])
    const context = useContext(HomeContext);
    const [isLoaded, setIsLoaded] = useState(false)

    const openSalon = (id) => {
        route(`/stylists/${id}`)
    }

    useEffect(() => {
        setIsLoaded(false)
        let countryName = 'Nigeria';
        if (context.isGhana) countryName = 'Ghana'
        if (context.isKenya) countryName = 'Kenya'
        getPopularStylists(20, countryName).then(res => {
            setRecommended(res.data)
            setIsLoaded(true)
        })
    }, [context.isNigeria, context.isGhana, context.isKenya])

    useEffect(() => {
        setIsLoaded(false)
        let countryName = 'Nigeria';
        if (context.isGhana) countryName = 'Ghana'
        if (context.isKenya) countryName = 'Kenya'
        getPopularStylists(20, countryName).then(res => {
            setRecommended(res.data)
            setIsLoaded(true)
        })
    }, [])

    return (
        <section className="recommended-stylist">
            <div className="content">
                <div className="heading">
                    <h2>More stylists</h2>
                    <p>We have found other beauticians you may like</p>
                </div>
                {!isLoaded ? <SearchResultsSkeleton /> : <div className="result-list">
                    {recommended.map(el => {
                        return (
                            <SalonCard key={`salon-card-${el.username}`} {...el} onClickExplore={openSalon}/>
                        )
                    })}
                </div>}
            </div>
        </section>
    )
}
import {h} from 'preact'
import ArticlePreview from "../blocks/ArticlePreview";
import {useContext} from "preact/hooks";
import {BlogContext} from "../../context/BlogContextProvider";

export default () => {
    const context = useContext(BlogContext)
    return (
        <section className="articles-box">
            <div className="heading">
                <h1>
                    <mark>Discover </mark>
                    with <mark className="underlined">Mirron</mark></h1>
            </div>
            <div className="categories">
                <ul>
                    <li><a className={!context.keywordSlug ? 'active' : ''}
                           href={`/articles`}>Latest</a></li>
                    {context.keywords.map(el => {
                        return (
                            <li><a className={el.slug === context.keywordSlug ? 'active' : ''} onClick={() => {
                                context.refresh()
                            }}
                                   href={`/articles/category/${el.slug}`}>{`${el.name}`}</a></li>
                        )

                    })}
                </ul>
            </div>
            <div className="article-preview-list">
                {context.articles.map((el, index) => {
                    return (
                        <ArticlePreview key={`article-post-${index}`} {...el} />
                    )
                })}
            </div>
            {!context.articlesPageMax && <div className="btn-more-holder">
                <a onClick={() => context.setArticlesPage(context.articlesPage + 1)}>
                    More
                    <img src="/assets/images/arrow-link-more.svg" alt="See more"/>
                </a>
            </div>}
        </section>
    )
}